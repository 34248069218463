import React from 'react'

export default function Hero() {
  return (
      <>
          <div className="text-center lg:text-left font-comfortaa items-center">
              <div className="container mx-auto xl:px-32 text-gray-800">
                  <div className="grid lg:grid-cols-2 gap-12  items-center">
                      <div className="mt-12 lg:mt-0">
                          <p className="text-base text-white pb-4 ">Welcome!</p>
                          <h1 className="text-4xl text-white pb-4 "><span className="text-yellow-500">Fast testing </span>for <span className="text-yellow-500">effective</span> and <span className="text-yellow-500">agile</span> product development.</h1>
                          <p className="text-base text-white pb-4">
                              Speed up your development team.
                          </p>
                      <button onClick={() => window.location = 'mailto:info@protonio.de'} className="text-base bg-yellow-500 rounded-lg px-4 py-2 hover:text-yellow-500 hover:bg-black hover:font-bold mr-5">free test</button>
                      <button onClick={() => window.location = 'mailto:info@protonio.de'} className="text-base bg-black text-yellow-500 rounded-lg px-4 py-2 hover:text-black hover:bg-yellow-500 hover:font-bold">talk to us</button>


                      </div>
                      <div className="w-full">
                          <img src="img/what_we_do_01.png" alt="HeroPic" className=' mx-auto ' width={75 + "%"} />
                      </div>
                  </div>
              </div>
          </div>
    </>
  )
}
