/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

export default function Navigation() {
    return (
        <>            
            <nav className="container flex mx-auto items-center py-5 px-5">


                <div className="py-1"><a href="https://www.protonio.de"><img src="img/protonio_logo_white.png" alt="logo" width={200}/></a> </div>
                
                
                <ul className='hidden sm:flex justify-end flex-1 px-5 items-center gap-6  text-neutral-50 text-base font-comfortaa' id="navbar-default">
                    <a href="./#about"><li className='cursor-pointer hover:border-b-2 hover:pb-3 hover:font-bold'>About</li></a>
                    <a href="./#products"><li className='cursor-pointer hover:border-b-2 hover:pb-3 hover:font-bold'>Products</li></a>
                    <a href="./#team" className='btn-link'><li className='cursor-pointer hover:border-b-2 hover:pb-3 hover:font-bold'>Team</li></a>
                    {/* <a href="/downloads#download" className='btn-link'><li className='bg-black rounded-lg px-4 py-2 hover:bg-yellow-500 items-center hover:text-black border-2 border-neutral-500 hover:font-bold'>Download SDK</li></a> */}
                    <a href="https://app.protonio.de" className='btn-link'><li className='bg-black rounded-lg px-4 py-2 hover:bg-yellow-500 items-center hover:text-black border-2 border-neutral-500 hover:font-bold'>Sign in</li></a>
                    
                </ul>
                </nav>
            </>
  )
}
