import React from 'react'

export default function Footer() {
  return (
    <footer className="bg-neutral-800">      
      <div className="container w-full mx-auto py-10 text-neutral-100">
        <ul className='hidden sm:flex justify-center flex-1 px-5 items-center gap-6  text-neutral-50 text-xs font-comfortaa'>
          <li className='cursor-pointer border-t-2 border-neutral-800 hover:border-neutral-100 pt-3 '><a href="./imprint">contact</a></li>
          <li className='cursor-pointer border-t-2 border-neutral-800 hover:border-neutral-100 pt-3 '><a href="https://www.instagram.com/protonio.de/">instagram</a></li>
          <li className='cursor-pointer border-t-2 border-neutral-800 hover:border-neutral-100 pt-3 '><a href="./#team" className='btn-link'>linkedIn</a></li>
          <li className='cursor-pointer border-t-2 border-neutral-800 hover:border-neutral-100 pt-3 '><a href="https://main.dev/" className='btn-link'>main.Dev</a></li>
          <li className='cursor-pointer border-t-2 border-neutral-800 hover:border-neutral-100 pt-3 '><a href="./imprint" className='btn-link'>imprint</a></li>
        </ul>
      </div>
    </footer>
  )
}
