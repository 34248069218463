import React from 'react'

export default function DownloadPage() {
  return (
    <div id="download" className="py-5 bg-neutral-100 text-center lg:text-left font-comfortaa items-center text-neutral-600">
      <div className="container mx-auto xl:px-32 ">
        Das ist die Tolle Download-Page und Doc!
      </div>
    </div>
  )
}
