import React from 'react'

export default function Team() {
    return (
        <div id="team" className="py-5 bg-neutral-100 text-center lg:text-left font-comfortaa items-center text-neutral-600">
            <div className="container w-2/4 mx-auto py-10">
                <h1 className='lg:text-2xl md:text-1xl sm:xl py-5'>our team</h1>
                <div className="grid grid-cols-3 gap-4">


                    <div className="text-center">
                        <img
                            src="img/avatar_BG.jpg"
                            className="rounded-full w-52 mb-4 mx-auto"
                            alt="Avatar Benjamin G."
                        />
                        <h5 className="text-xl font-medium leading-tight mb-2">Benjamin Götze</h5>
                        <p className="text-gray-500">Inventor of Protonio / CEO</p>
                    </div>

                    <div className="text-center">
                        <img
                            src="img/avatar_MH.jpg"
                            className="rounded-full w-52 mb-4 mx-auto"
                            alt="Avatar Maximilian H."
                        />
                        <h5 className="text-xl font-medium leading-tight mb-2">Maximilian Haupt</h5>
                        <p className="text-gray-500">Inventor of Protonio / CTO</p>
                    </div>
                    <div className="text-center">
                        <img
                            src="img/avatar-placeholder.gif"
                            className="rounded-full w-52 mb-4 mx-auto"
                            alt="Avatar Lukasz"
                        />
                        <h5 className="text-xl font-medium leading-tight mb-2">Łukasz Sowa</h5>
                        <p className="text-gray-500">Full Stack / DevOps Engineer</p>
                    </div>
                    <div className="text-center">
                        <img
                            src="img/avatar-placeholder.gif"
                            className="rounded-full w-52 mb-4 mx-auto"
                            alt="Avatar Mateusz"
                        />
                        <h5 className="text-xl font-medium leading-tight mb-2">Mateusz Wojdyła</h5>
                        <p className="text-gray-500">Software Developer</p>
                    </div>
                    <div className="text-center">
                        <img
                            src="img/avatar-placeholder.gif"
                            className="rounded-full w-52 mb-4 mx-auto"
                            alt="Avatar..."
                        />
                        <h5 className="text-xl font-medium leading-tight mb-2">Denny K&ouml;nigsmann</h5>
                        <p className="text-gray-500">Backend Developer</p>
                    </div>
                    <div className="text-center">
                        <img
                            src="img/avatar-placeholder.gif"
                            className="rounded-full w-52 mb-4 mx-auto"
                            alt="Avatar ..."
                        />
                        <h5 className="text-xl font-medium leading-tight mb-2">open seat</h5>
                        <p className="text-gray-500">Software Developer / UX-Designer</p>
                    </div>

                </div>
            </div>


        </div>
    )
}
