import React from 'react'

export default function Products() {
    return (
        <>

            <div id="products" className="py-5 bg-white-100 text-center lg:text-left font-comfortaa items-center text-neutral-600">
                <div className="container mx-auto xl:px-32 ">
                    <h1 className='lg:text-2xl md:text-1xl sm:xl'>our Products</h1>

                    <div className="px-5 my-5 grid lg:grid-cols-2 gap-12 flex items-center  bg-neutral-100">
                        <div className="w-full">
                            <p className="text-base  pb-5 text-neutral-400">Protonio<br/>cloud</p>
                            <p className="text-base  pb-4 ">
                                The Protonio Cloud enables decentralized user tests that
                                can take place on all browser-enabled devices. Whether
                                on your laptop at home, the VR systems in design studios
                                or on prototypes of the technical development team.
                            </p>
                            <p>
                                Almost any device able to run a browser can be connected
                                to the Protonio Cloud and turned into a test device.</p>
                        </div>
                        <div className="mt-12 lg:mt-0">
                            <img src="img/about_02.png" alt="about02" className=' mx-auto ' width={75 + "%"} />
                        </div>
                    </div>

                    <div className='grid lg:grid-cols-2 gap-12 '>
                        <div className="py-5 px-5 my-5 flex items-center  bg-neutral-100">
                            <div className="w-full">
                                <p className="text-base  pb-5 text-neutral-400">Protonio<br />devices</p>
                                <p className="text-base  pb-4 ">
                                    Transform functional prototypes into concrete
                                    test scenarios with our Protonio Devices. Without
                                    much effort your interface prototype is applied to
                                    the desired interfaces and a first validation can be
                                    performed. You can also use our Protonio Devices to
                                    connect your prototype with existing I/O ports and
                                    custom hardware.
                                </p>
                                <p>
                                    This makes it easier to decide which displays to
                                    purchase, or which interface concept performs best
                                    on the functional prototype.</p>
                            </div>
                        </div>

                        <div className="py-5 px-5 my-5 flex items-center  bg-neutral-100">
                            <div className="w-full">
                                <p className="text-base  pb-5 text-neutral-400">Protonio<br />services</p>
                                <p className="text-base  pb-4 ">
                                    In close collaboration with our customers, we offer
                                    you to quickly transfer your concepts into web
                                    applications.
                                </p>
                                <p>
                                    The web applications we develop provide additional
                                    functionality that will allow you to take your user
                                    testing and training to the next level. By monitoring
                                    them in the background, your users interactions can
                                    be documented which provides helpful insights for
                                    the operation of your future applications.
                                </p>
                            </div>
                        </div>

                    </div>
                    
                    
                    


                    <div id="about" className="py-5 text-center lg:text-left font-comfortaa items-center text-neutral-600">
                        <div className="py-5 grid lg:grid-cols-2 gap-12 flex items-center">
                            <div className="mt-12 lg:mt-0">
                                <img src="img/about_03.png" alt="about03" className=' mx-auto ' width={75 + "%"} />
                            </div>
                            <div className="w-full  bg-yellow-400 p-5">
                                <p className="text-base  pb-5 text-white text-">NEW!</p>
                                <p className="text-base  pb-5 ">
                                    With our Protonio-Sync plugin you can now have your
                                    interface concepts tested by different users at the same
                                    time, monitor them or actively control tasks.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
