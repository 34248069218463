
import './App.css';
import './comfortaa.css';
import Navigation from './components/Navigation';
import Hero from './components/Hero';
import Home from './components/Home';
import Footer from './components/Footer';
import Products from './components/Products';
import Imprint from './components/imprint';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import DownloadPage from "./components/DownloadPage.js"

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <header className="hero">
          <Navigation />
          <Hero />
        </header>
        <Routes>
          <Route path="/products" element={ <Products />} />
          <Route path="/" element={<Home />} />
          <Route path="/downloads" element={<DownloadPage />} />
          <Route path="/*" element={<Home />} />
          <Route path="/imprint" element={<Imprint />} />

        </Routes>
        <Footer/>
      </div>
    </BrowserRouter>
    
  );
}

export default App;
