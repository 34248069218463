import React from 'react'

export default function Clients() {
  return (


    <div id="clients" className="bg-white-100 text-center lg:text-left font-comfortaa items-center text-neutral-600">
      <div className="container w-2/4 mx-auto py-10">
        <h1 className='lg:text-2xl md:text-xl sm:xl py-5'>our clients</h1>

        <div className="py-5 text-center lg:text-left font-comfortaa items-center text-neutral-600">
          <img src="img/clients.png" alt="clients" width={50 + "%"} className="mx-auto" />
        </div>
      </div>
    </div>


  )
}
