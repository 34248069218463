import React from 'react'

export default function About() {
    return (
        <div id="about" className="py-5 bg-neutral-100 text-center lg:text-left font-comfortaa items-center text-neutral-600">
            <div className="container mx-auto xl:px-32 ">
                <div className="py-5 grid lg:grid-cols-2 gap-12  items-center">
                    <div className="w-full">
                        <img src="img/about_01.png" alt="about01" className=' mx-auto ' width={75 + "%"} />
                    </div>
                    <div className="mt-12 lg:mt-0">
                        <p className="text-base  pb-5 text-neutral-400">Whats we do!</p>

                        <p className="text-base pb-4">
                            With Protonio there are almost no more
                            limits when it comes to testing interface
                            concepts with users of your choice,
                            especially during the entire product
                            development process.</p>
                        <p className="text-base pb-4">
                            Protonio connects early interface
                            prototypes with users, developers and
                            designers, whether in VR, on your home
                            computer or on functional prototypes
                            from various R&D departments.</p>
                        <p className="text-base  pb-4">
                            For this, Protonio offers an interface
                            product that supports and accelerates
                            the agile development of products. This
                            closes a gap and greatly simplifies agile
                            collaboration in interdisciplinary teams.
                        </p>

                        
                       
                    </div>
                </div>
              
            </div>
        </div>
    )
}
