import React from 'react'
import About from './About'
import Team from './Team'
import Clients from './Clients'
import Products from './Products'
// import { Player, ControlBar, ReplayControl, ForwardControl,CurrentTimeDisplay,TimeDivider,PlaybackRateMenuButton,VolumeMenuButton } from 'video-react';

export default function Home() {
    return (
      <>
        {/* <Player
          playsInline
          poster="/assets/poster.png"
          src="./src/protonio_Vid_linkedIn_Export.mp4"
        >

        <ControlBar>
          <ReplayControl seconds={10} order={1.1} />
          <ForwardControl seconds={30} order={1.2} />
          <CurrentTimeDisplay order={4.1} />
          <TimeDivider order={4.2} />
          <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
          <VolumeMenuButton disabled />
          </ControlBar>
          </Player> */}

        <About />
        <Products />
        <Team />
        <Clients />

        </>
  )
}
